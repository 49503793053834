
import { map, take, tap, delay } from 'rxjs/operators';
import { SelectedPartnerService } from '../../../../services/partner/selectedPartner.service';
import { BehaviorSubject, Observable, Subject, Subscription, ReplaySubject } from 'rxjs';
import { UploadFileComponent } from '../../../../template/shared/components/upload-file/upload-file.component';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DELEGATE, EXECUTOR, ParticipantsTableComponent, WATCHER } from "../../participants-table/participants-table.component";
import { ActivatedRoute, Router } from "@angular/router";
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { TasksBackend } from "../../../../backend/tasks.backend";
import { LIST } from '../../../../services/lists/lists.data.service';
import { Store } from '../../../../template/shared/proto/storage/decorators';
import { SuggestionsComponent } from '../../../../components/suggestions/suggestions.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { ProtoInputComponent } from '../../../../template/shared/proto/components/input/protoInput.component';
import { EMPTY_PARTNER } from '../../../../app.constants';
import { PartnersBackend } from "../../../../backend/partners.backend";
import { dateToString, mainPerson, removeLastNewLineIfNeeded } from "../../../common/functions";
import { toDbString } from "../../../../functions/utils/date/date.utils";
import { TaskboardDataService } from "../../../services/taskboard-data.service";
import { TaskReview } from "../../../models/task-review";
import { Person } from "../../../../models/persons";
import { PersonsBackend } from "../../../../backend/persons.backend";
import anchorme from 'anchorme';
const UNKNOWN_ERROR = 'unknownError';
const UNKNOWN_ID = -9999;

@Component({
  selector: '.content_inner_wrapper',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss'],
})
export class NewTaskComponent implements OnInit, AfterViewInit, OnDestroy {
  // protected products: LIST = LIST.PRODUCTS;
  protected products: any[];
  protected taskTypes: LIST = LIST.REQUEST_TYPES;
  protected taskTypesMarketing: LIST = LIST.REQUEST_TYPES_MARKETING;
  protected services: LIST = LIST.SERVICES;
  protected priorities: LIST = LIST.PRIORITIES;
  protected marketingSources: LIST = LIST.MARKETING_SOURCES_LIST;
  protected marketingClientTypes: LIST = LIST.MARKETING_CLIENT_TYPES_LIST;
  protected projects: LIST = LIST.PROJECTS;
  protected partners: LIST = LIST.PARTNERS;
  protected selesTypes: LIST = LIST.SALES_TYPES_LIST;
  protected selesClientNeeds: LIST = LIST.SALES_CLIENTS_NEEDS_LIST;
  protected marketingCampaigns: LIST = LIST.MARKETING_CAMPAIGNS_LIST;

  protected EXECUTOR = EXECUTOR;
  protected WATCHER = WATCHER;
  protected DELEGATE = DELEGATE;

  private selectedPartner: number;

  protected submitted = false;
  protected _showForm = true;
  newTaskForm: UntypedFormGroup;
  values: Subject<any> = new Subject();
  searchValues: Subject<any> = new Subject();
  @Input() title: string;
  @Input() types: string;
  @Input() expenses: string;
  @Input() dateFrom;
  @Input() dateTo;
  @ViewChild('elDateFrom', { read: MatInput }) dFromEl: MatInput;
  @ViewChild('elExpenses', { read: MatInput }) expensesEl: MatInput;
  @ViewChild('elDescription', { read: MatInput }) descriptionEl: MatInput;
  @ViewChild('elDateTo', { read: MatInput }) dToEl: MatInput;
  @ViewChild('delegatesInput', { read: ProtoInputComponent }) delegatesInput: ProtoInputComponent<any>;
  @ViewChildren(SuggestionsComponent, { read: SuggestionsComponent }) suggestions: QueryList<SuggestionsComponent>;
  @ViewChildren(ProtoInputComponent, { read: ProtoInputComponent }) inputs: QueryList<ProtoInputComponent<any>>;
  @ViewChildren(MatDatepicker, { read: MatDatepicker }) dPickers: QueryList<MatDatepicker<any>>;
  @ViewChild(ParticipantsTableComponent) pTable: ParticipantsTableComponent;
  @ViewChild('participantChkBox', { read: TemplateRef, static: true }) participantChkBoxTpl: TemplateRef<any>;
  @ViewChild('watcherChkBox', { read: TemplateRef, static: true }) watcherChkBox: TemplateRef<any>;
  @ViewChild(UploadFileComponent) uploadFileCmp: UploadFileComponent;
  @ViewChild('partnerInput', { read: SuggestionsComponent }) partnerInput: SuggestionsComponent;
  @ViewChild('taskTypeInput', { read: SuggestionsComponent }) taskTypeInput: SuggestionsComponent;
  // @ViewChild('productsList', { static: false }) productsList: SuggestionsComponent;
  @ViewChild('productsList') productsList: ProtoInputComponent<any>;
  // @ViewChild('taskTypesList') taskTypesList: SuggestionsComponent;
  @ViewChild('servicesList') servicesList: SuggestionsComponent;

  @ViewChild('taskTypesList') set content(taskTypesList: SuggestionsComponent) {
    this.taskTypesList = taskTypesList;
    if (taskTypesList) {
      this._subscriptions.push(taskTypesList.suggestionObjSelected.subscribe((s) => {
        this._taskTypeSelected = s;
        if (this.isMarketing) {
          this.changeFormStateMarketing(true);
        } else if (this.isSale) {
          this.changeFormStateSales();
        } else if (!this.isSale) {
          this.changeFormStateMarketing(false);
        }
      }))
    }
  }

  taskTypesList: SuggestionsComponent;

  delegates: Observable<Person[]> = new BehaviorSubject([]);
  delegatesSource: Function;
  protected _personsLoading: boolean;
  protected _today = new Date();
  // private personsTblModel = {
  //   columns: [
  //     {
  //       name: '',
  //       prop: 'name',
  //       sortable: false,
  //     },
  //     {
  //       name: 'Dalyvis',
  //       cellTemplate: () => this.participantChkBoxTpl
  //     },
  //     {
  //       name: 'Stebetojas',
  //       cellTemplate: () => this.watcherChkBox
  //     }
  //   ],
  // };

  executorId;
  delegateId;
  persons: Map<number, Array<any>> = new Map();
  _taskInserting = false;
  _taskInserted = false;

  @Store({ refresh: true }, 'WORKS') _test;
  protected description: string;

  dFrom;
  dTo;
  parentTaskDeadline;
  private personsSubj: Subject<any> = new BehaviorSubject([]);
  private filesKey;
  protected getPersons: Function;
  private _subscriptions = new Array<Subscription>();

  parent: TaskReview;

  productsSource = () => this.productsListObs;

  private productsListObs: Subject<any> = new ReplaySubject(1);

  private _taskTypeSelected;
  get isMarketing(): boolean {
    return this._taskTypeSelected?.code === 'VT_5';
  }
  get isSale(): boolean {
    return this._taskTypeSelected?.code === 'VT_3';
  }
  campaignsId;
  isMarketingSource: boolean = false;
  isSaleSource: boolean = false;

  plainTextDescription: string;
  descriptionWithLinks: string;
  descriptionText = '';

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': ['Sans', 'monospace'] }],
        [{ 'align': [] }],
        ['clean'],                                         // remove formatting button
      ],
    },
  }

  //@LoadingError()
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private location: Location,
    private tasksBackend: TasksBackend,
    private partnersBackend: PartnersBackend,
    private personsBackend: PersonsBackend,
    private chg: ChangeDetectorRef,
    private selectedPartnerService: SelectedPartnerService,
    private _taskboardDataService: TaskboardDataService,
    private route: ActivatedRoute,
  ) {
    this.delegatesSource = () => this.delegates;
    this.getPersons = () => personsBackend.getEmployees();
  }

  ngOnInit() {
    this._subscriptions.push(this.route.data.subscribe((data) => {
      this.isMarketingSource = data.sourceRoute == 'marketing';
      this.isSaleSource = data.sourceRoute == 'sales';
      if (this.isMarketingSource) {
        this.changeFormStateMarketing(true);
      }
      if (this.isSaleSource) {
        this.changeFormStateSales();
      }
    }));
    this._subscriptions.push(this.route.params.subscribe((pars) => {
      this.campaignsId = pars['id'];
    }));
    this.dFrom = new Date('' + this._today.getFullYear() + '-' + (this._today.getMonth() + 1) + '-' + this._today.getDate());
    this.dTo = this.dFrom;
    this._subscriptions.push(
      this.delegates.pipe(delay(1)).subscribe((dl) => {
        if (this.delegatesInput) {
          this.delegatesInput.select(this.parent && this.parent.delegateId ? ((d: any) => d.id == this.parent.delegateId) : mainPerson);
        }
      })
    );
    this.buildForm();
  }

  ngAfterViewInit(): void {
    this._subscriptions.push(this.selectedPartnerService.selectedPartner
      .subscribe((p) => {
        if (p.id != EMPTY_PARTNER) {
          this.partnerInput.input.select((pr) => p.id == (<any>pr).id);
        }
      }));
    if (this.isMarketingSource) {
      this.partnerInput.input.select((pr) => (<any>pr).code === 'ECRM_PK');
      this.taskTypeInput.input.select((pr) => (<any>pr).code === 'VT_5');
    } else if (this.isSaleSource) {
      this.taskTypeInput.input.select((pr) => (<any>pr).code === 'VT_3');
    }
    // this._subscriptions.push(this.taskTypesList.suggestionObjSelected.subscribe((s) => {
    //   this._taskTypeSelected = s;
    //   if (this.isMarketing) {
    //     this.changeFormStateMarketing(true);
    //   } else if (this.isSale) {
    //     this.changeFormStateSales();
    //   } else if (this.isSale) {
    //     this.changeFormStateMarketing(false);
    //   }
    // }))
    this._prepareForChild();
  }

  private _prepareForChild() {
    const task = this._taskboardDataService.parentTask;
    if (task) {
      if (this.productsList && task.productId) {
        this.productsList.select(p => (<any>p).id == task.productId);
      }
      if (this.taskTypesList && task.taskTypeId) {
        this.taskTypesList.input.select(t => (<any>t).id == task.taskTypeId);
      }
      if (this.partnerInput && task.partnerId) {
        this.partnerInput.input.select(p => (<any>p).id == task.partnerId);
      }
      if (this.delegatesInput && task.delegateId) {
        this.delegatesInput.select(d => (<any>d).id == task.delegateId)
      }
      if (this.servicesList && task.serviceId) {
        this.servicesList.input.select(s => (<any>s).id == task.serviceId)
      }
      this.dFrom = task.dateStart;
      this.newTaskForm.controls.dateFrom.setValue(task.dateStart);
      this.dTo = task.dateDeadline;
      this.newTaskForm.controls.dateTo.setValue(task.dateDeadline);
    }
    this.parent = task;
    this.newTaskForm.addControl('addFilesFromParent', new UntypedFormControl(true));
  }

  onContentChanged(event) {
    // below commented code has problems with new lines
    this.plainTextDescription = removeLastNewLineIfNeeded(event?.text) || null;
    this.descriptionWithLinks = event && event.html ? anchorme(event.html) : null;
  }

  partnerSelected(partnerId: number) {
    this.selectedPartner = partnerId;
    this._loadProducts(partnerId);
    this.delegateId = null;
    if (this.selectedPartner) {
      this.partnersBackend.getPartnerEmployees(partnerId).subscribe((pd) => {
        (<Subject<any>>this.delegates).next(pd);
      });
    } else {
      (<Subject<any>>this.delegates).next([]);
    }
  }

  addedMarketingFields(): boolean {
    //add condition here for selected task type = marketing to show marketing fields
    return this.isMarketing || this.isMarketingSource;
  }

  private _loadProducts(sp) {
    if (!sp || sp == EMPTY_PARTNER) {
      this.products = [];
      this.productsListObs.next(this.products);
    } else {
      this.tasksBackend.getRequestProductsByPartner(sp)
        // .subscribe((res) => this.productsList.next(res.map(el => { return { ...el, isClientProduct: el.id % 2 == 0 ? true : false } })));
        .subscribe((res) => { this.products = res; this.productsListObs.next(this.products); });
    }
  }

  dateChanged(event, el, component) {

    switch (component) {
      case "from":
        this.dateFrom = dateToString(event.value);
        this.dFrom = event.value;
        break;
      case "to":
        this.dateTo = dateToString(event.value);
        this.dTo = event.value;
        break;
    }
    el.close && el.close();
  }

  personsChanged(persons) {
    this.persons = persons;
  }

  insertTask(taskForm) {
    this.submitted = true;
    if (taskForm.invalid || !this.plainTextDescription) {
      return;
    }
    taskForm = taskForm.value;
    if (this.addedMarketingFields()) {
      taskForm.companyName = taskForm.marketingPotentialPartner;
      delete taskForm.marketingPotentialPartner;
      taskForm.name = taskForm.marketingContactFirstName;
      delete taskForm.marketingContactFirstName;
      taskForm.lastname = taskForm.marketingContactLastName;
      delete taskForm.marketingContactLastName;
      taskForm.email = taskForm.marketingContactEmail;
      delete taskForm.marketingContactEmail;
      // taskForm.phone = taskForm.marketingContactPhone;
      delete taskForm.marketingContactPhone;
      taskForm.clientTypeId = taskForm.marketingClientTypeId;
      delete taskForm.marketingClientTypeId;
      taskForm.sourceId = taskForm.marketingSourceId;
      delete taskForm.marketingSourceId;
      // if not isMarketing, no need to override campaignsId from form
      if (this.isMarketingSource) {
        taskForm.campaignsId = +this.campaignsId;
        delete taskForm.marketingCampaignsId;
      } else {
        taskForm.campaignsId = +taskForm.marketingCampaignsId;
        delete taskForm.marketingCampaignsId;
      }
    } else {
      delete taskForm.marketingPotentialPartner;
      delete taskForm.marketingContactFirstName;
      delete taskForm.marketingContactLastName;
      delete taskForm.marketingContactEmail;
      delete taskForm.marketingContactPhone;
      // delete taskForm.marketingCampaignsId;
      // delete taskForm.marketingSourceId;
    }
    if (this.isSale || this.isSaleSource) {
      taskForm.masterTaskId = null;
      taskForm.campaignsId = +taskForm.marketingCampaignsId;
      taskForm.klNeed = taskForm.saleClientNeedsId;
      taskForm.klType = taskForm.marketingClientTypeId;
      taskForm.saleType = taskForm.saleTypeId;
      taskForm.pCost = taskForm.saleValue;
      taskForm.sourceId = taskForm.marketingSourceId;
    }
    delete taskForm.marketingClientTypeId;
    delete taskForm.saleClientNeedsId;
    delete taskForm.marketingClientTypeId;
    delete taskForm.saleTypeId;
    delete taskForm.saleValue;
    delete taskForm.marketingSourceId;
    delete taskForm.marketingCampaignsId;
    if (this.parent) {
      taskForm['parentId'] = this.parent.id;
    }
    if (taskForm.dateFrom) {
      taskForm.dateFrom = toDbString(taskForm.dateFrom);
    }
    if (taskForm.dateTo) {
      taskForm.dateTo = toDbString(taskForm.dateTo);
    }
    this.executorId = this.getFirstId(EXECUTOR);
    if (!this.executorId) {
      this.executorNotSelected();
    } else {
      taskForm.executorId = this.executorId;
      taskForm.watchers = this.persons.has(WATCHER) ? this.persons.get(WATCHER).map((w) => ({ personsId: w.id })) : [];
      taskForm.participants = this.persons.has(DELEGATE) ? this.persons.get(DELEGATE).map((w) => ({ personsId: w.id })) : [];
      const expenses = ((taskForm.expenses || '') + '').replace(',', '.');
      taskForm.expenses = expenses !== '' ? parseFloat(expenses) : null;
      taskForm.description = this.plainTextDescription;
      taskForm.descriptionHTML = this.descriptionWithLinks;
      this._insert(taskForm).subscribe((taskId) => {
        if (taskId > 0) {
          this.taskInserted(taskId, this.router);
        } else {
          this.taskNotInserted(UNKNOWN_ERROR);
          try {
            this.chg.detectChanges();
          } catch (e) { }
        }
      },
        (err) => {
          this.taskNotInserted(err);
          this._taskInserting = false;
          try {
            this.chg.detectChanges();
          } catch (e) { }
        });
    }
  }

  private getFirstId(type: number) {
    const arr = this.persons.has(type) ? this.persons.get(type) : [];
    return arr.length > 0 ? arr[0].id : null;
  }

  //    @Load('taskInserting')
  private _insert(taskForm) {
    this._taskInserted = false;
    this._taskInserting = true;
    if (this.uploadFileCmp) {
      taskForm.filesKey = this.uploadFileCmp.key;
    }
    taskForm.title = taskForm.description;
    return this.tasksBackend.insertTask(taskForm).pipe(tap((_) => this._taskInserting = false));
  }

  changeFormStateMarketing(required: boolean) {
    if (!this.newTaskForm) return;
    if (required) {
      !this.isMarketingSource && this.newTaskForm.controls.marketingCampaignsId.setValidators([Validators.required]);
      this.newTaskForm.controls.marketingSourceId.setValidators([Validators.required]);
      this.newTaskForm.controls.marketingClientTypeId.setValidators([Validators.required]);
      // this.newTaskForm.controls.productId.setValidators([Validators.required]);
    } else {
      this.newTaskForm.controls.marketingCampaignsId.setValidators(null);
      this.newTaskForm.controls.marketingSourceId.setValidators(null);
      this.newTaskForm.controls.marketingClientTypeId.setValidators(null);
      // this.newTaskForm.controls.productId.setValidators(null);
    }
    this.newTaskForm.controls.marketingCampaignsId.updateValueAndValidity();
    this.newTaskForm.controls.marketingSourceId.updateValueAndValidity();
    this.newTaskForm.controls.marketingClientTypeId.updateValueAndValidity();
    // this.newTaskForm.controls.productId.updateValueAndValidity();
  }
  changeFormStateSales() {
    if (!this.newTaskForm) return;
    this.newTaskForm.controls.marketingCampaignsId.setValidators(null);
    // this.newTaskForm.controls.productId.setValidators(null);
    this.newTaskForm.controls.marketingSourceId.setValidators([Validators.required]);
    this.newTaskForm.controls.marketingClientTypeId.setValidators([Validators.required]);
    this.newTaskForm.controls.marketingCampaignsId.updateValueAndValidity();
    this.newTaskForm.controls.marketingSourceId.updateValueAndValidity();
    this.newTaskForm.controls.marketingClientTypeId.updateValueAndValidity();
    // this.newTaskForm.controls.productId.updateValueAndValidity(null);
  }

  autoUpdateDescription() {
    if (this.isMarketing || this.isMarketingSource) {
      let description = `${this.newTaskForm.controls.marketingPotentialPartner.value || ''} ${this.newTaskForm.controls.marketingContactPhone.value || ''} ${this.newTaskForm.controls.marketingContactFirstName.value || ''} ${this.newTaskForm.controls.marketingContactLastName.value || ''} ${this.newTaskForm.controls.marketingContactEmail.value || ''}`;
      // this.newTaskForm.controls.description.setValue(description);
      // this.newTaskForm.controls.description.updateValueAndValidity();
      this.descriptionText = description;
    }
  }

  buildForm() {
    //dafault form in state marketing = false;
    this.newTaskForm = this.formBuilder.group(
      {
        taskTypeId: [null, Validators.required],
        dateFrom: [this.dFrom, Validators.required],
        dateTo: [this.dTo, Validators.required],
        partnerId: [null, Validators.required],
        productId: [null, this.isMarketingSource ? Validators.required : null],
        serviceId: [null, Validators.required],
        priorityId: [null, Validators.required],
        mainTaskId: [''],
        expenses: [''],
        executorId: [this.executorId],
        delegateId: [this.delegateId],
        // description: [this.description, Validators.required],
        marketingPotentialPartner: [null],
        marketingContactFirstName: [null],
        marketingContactLastName: [null],
        marketingContactEmail: [null, this.addedMarketingFields() ? Validators.required : null],
        marketingContactPhone: [null],
        marketingCampaignsId: [null],
        marketingSourceId: [null, this.addedMarketingFields() ? Validators.required : null],
        marketingClientTypeId: [null, this.addedMarketingFields() ? Validators.required : null],
        saleClientNeedsId: [null],
        campaignsId: [null,],
        saleTypeId: [null],
        saleValue: [null],
      });
    this.newTaskForm.controls.marketingPotentialPartner.valueChanges.subscribe(() => {
      this.autoUpdateDescription();
    });
    this.newTaskForm.controls.marketingContactFirstName.valueChanges.subscribe(() => {
      this.autoUpdateDescription();
    });
    this.newTaskForm.controls.marketingContactLastName.valueChanges.subscribe(() => {
      this.autoUpdateDescription();
    });
    this.newTaskForm.controls.marketingContactEmail.valueChanges.subscribe(() => {
      this.autoUpdateDescription();
    });
    this.newTaskForm.controls.marketingContactPhone.valueChanges.subscribe(() => {
      this.autoUpdateDescription();
    });
  }

  getFormControlValue(control) {
    return this.newTaskForm.controls[control].value;
  }

  inputChanged(event) {
    this.title = event.target.value;
  }

  private executorNotSelected() {
    swal.fire({ title: "Nepasirinktas vykdytojas!", timer: 2000, showConfirmButton: false, icon: 'error' })
    // .catch(swal.noop);
  }

  private taskNotInserted(error) {
    if (error !== UNKNOWN_ERROR) {
      swal.fire({ title: error?.error?.split('\n')[0] || "Užduoties pridėti nepavyko", timer: 5000, showConfirmButton: false, icon: 'error' })
    } else {
      this._clear();
      swal.fire({ title: "Užduoties pridėti nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' })
    }
    // .catch(swal.noop);
  }

  private taskInserted(taskId, router: Router) {
    this.uploadFileCmp.refresh();
    this._taskInserted = true;
    this._clear();
    swal.fire({
      title: 'Nauja užduotis pridėta',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Eiti į užduotį',
      cancelButtonText: 'Eiti į sąrašą'
    }).then((result) => {
      if (result.value) {
        this.redirectToTask(taskId);
      } else if (result.dismiss === swal.DismissReason.cancel) {
        if (this.isMarketingSource) {
          router.navigate(['/marketing/amt']);
        } else if (this.isSaleSource) {
          router.navigate(['/sales/s']);
        } else {
          router.navigate(['/taskboard/t/a']);
        }
      }
    });
  }

  private redirectToTask(taskId) {
    this.router.navigate(['/taskboard/t/v/', taskId]);
  }

  cancel() {
    this.location.back();
  }

  private _clear() {
    this.submitted = false;
    this.inputs.forEach((l) => l.deselect());
    this.suggestions.forEach((l) => l.deselect());
    this.dFromEl.value = null;
    this.dToEl.value = null;
    this.pTable.clear();
    // this.descriptionEl.value = null;
    this.descriptionText = null;
    this.title = null;
    this.expenses = null;
    this.expensesEl.value = null;
    this.parent = null;
    this.descriptionText = null;
    this.plainTextDescription = null;
    this.descriptionWithLinks = null;
    try {
      this._showForm = false;
      this.chg.detectChanges();
      setTimeout(() => {
        this._showForm = true;
        this.buildForm();
        try {
          this.chg.detectChanges();
        } catch (err) { }
      });
    } catch (e) { }
  }

  private _personSelected(row, checked, t) {
    const sortByNameFn = (a, b) => a.name > b.name ? 1 : -1;
    const sortByName = (arr: Array<any>) => arr.sort(sortByNameFn);
    row.type = checked ? t : null;
    this._subscriptions.push(this.personsSubj.pipe(take(1),
      map((pl: Array<any>) => pl.reduce((acc, curr) => {
        const arr = acc[curr.type > 0 ? 0 : 1];
        arr.push(curr);
        return acc;
      }, [[], []])))
      .subscribe((pl: Array<Array<any>>) => this.personsSubj.next([...sortByName(pl[0]), ...sortByName(pl[1])])));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}

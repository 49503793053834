
import { of as observableOf, BehaviorSubject, ReplaySubject, Subject, Subscription, Observable } from 'rxjs';

import { catchError, retry, tap, take, withLatestFrom, switchMap, shareReplay, filter, map, distinctUntilChanged, pluck } from 'rxjs/operators';
import { JobsListComponent } from '../components/jobs/jobsList/jobsList.component';
import { TaskboardSidebarService } from '../services/taskboardSidebar.service';
import { ChangeDetectorRef, ElementRef, ViewChild, Directive } from '@angular/core';
import {
  CommentTask,
  TaskCommented,
  TaskPriorityChanged,
  TasksBackend,
  TaskStateChanged,
  TaskProductChanged
} from '../../backend/tasks.backend';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_MODAL_OPTS } from './constants';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { error, Loadable, loaded, loading } from '../../template/shared/proto/common/loadable/loadable';
import { ERROR_NOT_FOUND, UNKNOWN_ERROR } from '../../core/errors';
import { Task } from '../../models/Task';
import { notNull } from '../../template/shared/proto/helpers';
import { BEHAVIOR, StateService } from '../../services/state/stateService';
import { JOB_ENTERED, JOB_UPDATED, OPEN_JOBS_TAB } from '../../state/state';
import swal from 'sweetalert2';
import { TransferFormComponent } from '../components/modals/transfer-form/transfer-form.component';
import { FOR_ME, REQUESTS, REVIEW, TASKS } from '../taskboard.help';
import { UpdateRequestStateComponent } from '../components/modals/update-request-state/updateRequestState.component';
import { ComponentBase } from '../../template/shared/proto/components/component.base';
import { NearestActionFormComponent } from '../components/modals/nearest-action-component/nearestActionForm.component';
import { ListModalComponent } from '../../template/shared/components/modals/listModal/listModal.component';
import { PREVIOUS_URL } from "../../template/shared/proto/services/routing.service";
import { UpdateTaskPriorityComponent } from "../components/modals/update-task-priority/update-task-priority.component";
import { TaskboardDataService } from "../services/taskboard-data.service";
import { UpdateRequestTypeComponent } from "../components/modals/update-request-type/update-request-type.component";
import { TimerService } from "../../template/shared/services/timer/timer-service";
import { msToTimeString, Timer, TimerState } from "../../template/shared/services/timer/timer";
import { CANCELLED, TimerDialogComponent } from "../../template/shared/components/timer/timer-dialog/timer-dialog.component";
import { REQUEST_SERVICE_ID } from "../../app.constants";
import { DistributeTaskComponent } from "../components/modals/distribute-task/distribute-task.component";
import { UserService } from "../../services/user/user.service";
import { JobFormModel } from "../models/job-form";
import { JobsListEntry } from "../models/jobs-list-entry";
import { NearestAction, TaskReview } from "../models/task-review";
import { EntityUpdated } from "../../core/api/response/entity-updated";
import { EntityCreated } from "../../core/api/response/entity-created";
import { CommentsComponent } from "../components/comments/comments.component";
import { JobModel } from "../models/job-model";
import { DATE_WITH_TIME, SHORT_DATE } from "../../core/time";
import { JobEntered } from "../../backend/jobs/jobs.backend";
import { ActionModalComponent } from '../components/actions-modal/action-modal.component';
import { UpdateTaskDeadlineComponent } from '../components/modals/update-task-deadline/update-task-deadline.component';
import { UpdateTaskServiceComponent } from '../components/modals/update-service-component/update-task-service.component';
import { UpdateRequestProductComponent } from '../components/modals/update-request-product/update-request-product-modal.component';
import { AuthService } from '../../services/auth.service';
import { convertNumToTime } from '../common/functions';
import { ProjectEditModalComponent } from '../../components/project-edit-modal/project-edit-modal.component';
import { DpaListComponent } from '../components/requests/dpa-list/dpa-list.component';
import { UpdateProjectStageComponent } from '../components/modals/update-project-stage/update-project-stage-modal.component';
import { SelectProjectModalComponent } from '../components/modals/select-project/select-project-modal.component';
import { UpdateDelegateModalComponent } from '../components/modals/update-delegate-modal/update-delegate-modal.component';
import { TASK_STATE_COMPLETED } from '../../core/constants';
import { UpdateTaskPartnerComponent } from '../components/modals/update-task-partner/update-task-partner.component';
import { SalesFieldsModalComponent } from '../components/sales-fields-modal/sales-fields-modal.component';

export const TASK_NOT_FOUND = "TASK NOT FOUND";

@Directive()
export abstract class TaskReviewBase extends ComponentBase {
  @ViewChild(JobsListComponent) jobsList: JobsListComponent;
  @ViewChild(CommentsComponent) commentsComponent: CommentsComponent;
  @ViewChild(DpaListComponent) dpaListComponent: DpaListComponent;
  @ViewChild('tabSet', { read: ElementRef }) tabSet: ElementRef;
  protected jobsTabActive: boolean;
  protected childTasksTabActive: boolean;
  protected _data: Subject<Loadable<TaskReview>> = new ReplaySubject(1);
  protected _task: Observable<TaskReview>;
  protected _id: Observable<number>;
  protected _subscriptions: Array<Subscription> = [];

  protected dateWithTime = DATE_WITH_TIME;
  protected shortDate = SHORT_DATE;

  protected _nearestActionsLoading = false;
  protected _nearestActionsLoaded = false;
  private _actionsHistory: Subject<Array<any>> = new BehaviorSubject([]);
  protected getPersons: Function;
  protected getPersonsProto: Function;

  protected task: TaskReview;
  protected get previous(): Observable<Task> {
    return this.taskboardDataService.previous;
  }

  private _timerSubscription: Subscription;
  private _hasTimersSubscription: Subscription;

  protected get next(): Observable<Task> {
    return this.taskboardDataService.next;
  }

  subs1: Subscription;
  subs2: Subscription;

  public currentTabId: string = 'tab01';
  private _taskTimer: Timer;
  get _timerClass(): string {
    if (!this._taskTimer || this._taskTimer.state == TimerState.PAUSED) {
      return 'zmdi-timer';
    }
    return 'zmdi-timer-off';
  }

  get _timerText(): string {
    if (!this._taskTimer || this._taskTimer.state == TimerState.PAUSED) {
      return 'PALEISTI LAIKMATĮ';
    }
    return 'SUSTABDYTI LAIKMATĮ';
  }

  _hasTimers$ = new BehaviorSubject(false);
  _takeTaskExecuting = false;

  jobDpaChangeSubs: Subscription;
  personsChanged = new Subject();

  public triggerUpdateChildTree = 0;
  public productTime = null;
  public isProject: boolean;
  public projectDpaList: any[];

  constructor(protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected tasksBackend: TasksBackend,
    protected state: StateService,
    protected sidebarService: TaskboardSidebarService,
    protected router: Router,
    protected chg: ChangeDetectorRef,
    protected taskboardDataService: TaskboardDataService,
    protected timerService: TimerService,
    protected host: HTMLElement,
    protected userService: UserService,
    protected authService: AuthService
  ) {
    super();
  }

  ngOnInit() {
    if (this.route.parent && this.route.parent.parent && this.route.parent.parent.snapshot && this.route.parent.parent.snapshot.url && this.route.parent.parent.snapshot.url[0] && this.route.parent.parent.snapshot.url[0].path === 'projects') {
      this.isProject = true;
    } else {
      this.isProject = false;
    }
    this._setupRouteWatch();
    this._setupStateListeners();
    this._setupPersonsLoading();
    this._setupInitialState();
    this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
      this.task = t;
      if (this.task && this.task.isRequest) {
        if (this.task.partnerId && this.task.productId) {
          this._subscriptions.push(this.tasksBackend.getPartnerProductTime(this.task.partnerId, this.task.productId).subscribe((res) => {
            this.productTime = res;
            this.productTime.computed = {};
            // this.productTime.computed.usedTimeNum = this.productTime.numericLeft ? this.productTime.numericMonth - this.productTime.numericLeft : this.productTime.numericPaid;
            // this.productTime.computed.totalTimeNum = this.productTime.numericLeft ? this.productTime.numericMonth : this.productTime.computed.usedTimeNum;
            // this.productTime.computed.usedTimePerc = this.productTime.computed.totalTimeNum ? this.productTime.computed.usedTimeNum / this.productTime.computed.totalTimeNum * 100 : 0;
            this.productTime.computed.usedTimeNum = this.productTime.numericLeft ? this.productTime.numericMonth * 60 - this.productTime.numericLeft : this.productTime.numericPaid * 60;
            this.productTime.computed.totalTimeNum = this.productTime.numericLeft ? this.productTime.numericMonth * 60 : this.productTime.computed.usedTimeNum;
            this.productTime.computed.usedTimePerc = this.productTime.computed.totalTimeNum ? this.productTime.computed.usedTimeNum / this.productTime.computed.totalTimeNum * 100 : 0;
            // this.productTime.computed.usedTimeStr = convertNumToTime(this.productTime.computed.usedTimeNum);
            // this.productTime.computed.totalTimeStr = convertNumToTime(this.productTime.computed.totalTimeNum);
            try {
              this.chg.detectChanges();
            } catch (e) { }
          }));
        } else {
          this.productTime = null;
          try {
            this.chg.detectChanges();
          } catch (e) { }
        }
      } else {
        this.productTime = null;
        try {
          this.chg.detectChanges();
        } catch (e) { }
      }
      if (this.subs1) this.subs1.unsubscribe();
      this.subs1 = this.state.getStream(JOB_UPDATED).subscribe(() => {
        // console.log('getTaskDetails b1');
        const subs = this._refreshTask(t.id).subscribe(() => {
          subs.unsubscribe();
        }, (err) => {
          subs.unsubscribe();
        });
      })
      if (this.subs2) this.subs2.unsubscribe();
      this.subs2 = this.state.getStream(JOB_ENTERED).subscribe(() => {
        // console.log('getTaskDetails b2');
        const subs = this._refreshTask(t.id).subscribe(() => {
          subs.unsubscribe();
        }, (err) => {
          subs.unsubscribe();
        });
      })
    }));
    this._subscriptions.push(this.taskboardDataService.refreshDatatable.subscribe(() => {
      this.projectDpaTabOpened(this.task);
    }))
  }

  ngOnDestroy() {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
      this._timerSubscription = undefined;
    }
    this.timerService.activeTask = undefined;
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private _setupRouteWatch() {
    const id = this.route.params.pipe(pluck('id'), distinctUntilChanged(), map((n: string) => parseInt(n)));
    this._id = id.pipe(filter((i) => !isNaN(i)), shareReplay(1));
    this._subscriptions.push(
      id.pipe(filter((i) => isNaN(i))).subscribe((r) => this._data.next({
        data: null,
        error: 'Mallformed URL',
        isLoading: false
      })) //Fiksuojam neteisinga URL
    );
    this._subscriptions.push(
      this._id.pipe(
        switchMap((id) => {
          if (this.jobDpaChangeSubs) {
            this.jobDpaChangeSubs.unsubscribe();
          }
          // console.log('obs1');
          this.jobDpaChangeSubs = this.taskboardDataService.jobDpaChange.subscribe(() => {
            // console.log('getTaskDetails1');
            const subs = this.tasksBackend.getTaskDetails(id, this.isProject).subscribe((task) => {
              subs.unsubscribe();
              this._data.next(loaded(task));
              // this.jobsTabActive = true;
              this.currentTabId = 'tab02';
            }, (err) => {
              subs.unsubscribe();
            });
          })
          return this._loadTaskData(id);
        })).subscribe() //Pasikeitus ID, perkraunam requesta
    );
  }

  private _setupInitialState() {
    //Jei atidarius uzklausa reikia atidaryti darbu taba
    this.state.getStream(OPEN_JOBS_TAB, BEHAVIOR).pipe(withLatestFrom(this._id, (id1, id2) => ({
      id1,
      id2
    })), take(1), filter((i) => i.id1 == i.id2)).subscribe(
      (_) => {
        // this.jobsTabActive = true;
        this.currentTabId = 'tab02';
        this.state.notify(OPEN_JOBS_TAB, null);
      }
    );
  }

  private _loadTaskData(id) {
    return observableOf(id).pipe(
      tap((_) => this._data.next(loading())),
      switchMap((i) => { return this.tasksBackend.getTaskDetails(i, this.isProject) }), retry(2),
      catchError((e: Error) => e.name == ERROR_NOT_FOUND ? [TASK_NOT_FOUND] : [UNKNOWN_ERROR]),
      map((r) => this['mapTask'] && typeof this['mapTask'] == "function" ? this['mapTask'](r) : r),
      tap((r) => {
        if (typeof r == "string") {
          this._data.next(error(r));
        } else {
          const task: TaskReview = r;
          this.taskboardDataService.setCurrentTask(task);
          if (this._timerSubscription) {
            this._timerSubscription.unsubscribe();
            this._timerSubscription = undefined;
          }
          if (this._hasTimersSubscription) {
            this._hasTimersSubscription.unsubscribe();
            this._hasTimersSubscription = undefined;
          }
          this._timerSubscription = this.timerService.activeTimerForTask$(id).subscribe((timer) => {
            if (!timer) {
              this._taskTimer = undefined;
            } else {
              this._taskTimer = timer;
            }
            try {
              this.chg.detectChanges();
            } catch (err) { }
          });
          this._hasTimersSubscription = this.timerService.getTaskTimers$(id).subscribe((timers) => {
            this._hasTimers$.next(timers.length > 0);
          });
          // task.taskTimers = task.taskTimers && task.taskTimers.map(t => ({ ...t, visibleTime: msToTimeString(t.allTime), title: t.title ? t.title.toString() : '' })).reverse();
          // task.taskTimers = [...task.taskTimers];
          this.timerService.activeTask = id;
          this._checkDistributeTask(task);
          this._data.next(loaded(task));
        }
      }));
  }

  protected _checkDistributeTask(t: TaskReview) {
    const user = this.userService.user;
  }

  protected _updateTask(r: TaskReview): Observable<TaskReview> {
    // console.log('getTaskDetails A');
    return this.tasksBackend.getTaskDetails(r.id, this.isProject).pipe(filter(notNull), tap((r) => this._data.next(loaded(r))));
  }

  protected _refreshTask(taskId): Observable<TaskReview> {
    // console.log('getTaskDetails B');
    return this.tasksBackend.getTaskDetails(taskId, this.isProject).pipe(filter(notNull), tap((r) => this._data.next(loaded(r))));
  }

  protected takeTaskClicked(task: TaskReview) {

    if (task.taskTypeCode === 'VT_0') {
      swal.fire({
        title: 'Perdavimas negalimas, pasirinkite tipą.',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Atšaukti',
        confirmButtonText: 'Keisti tipą',
        // confirmButtonClass: 'btn-primary',
        customClass: {
          confirmButton: 'btn-primary'
        },
        // buttonsStyling: false
      })
        .then((r) => {
          if (r.value) {
            this.updateTaskTypeClicked(task);
          }
        });
    } else {
      if (this._takeTaskExecuting) {
        return;
      }
      this._takeTaskExecuting = true;
      this.tasksBackend.takeTask(task.id).subscribe((taken) => {
        this._takeTaskExecuting = false;
        if (taken.success) {
          this.task.taskTaken(taken.entity);
          this.detectChanges();
          swal.fire({
            title: "Užklausos vykdymas perimtas sėkmingai",
            timer: 2000,
            showConfirmButton: false,
            icon: 'success'
          });
        } else {
          swal.fire({ title: "Užklausos vykdymo perimti nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
        }
      });
    }
  }

  protected transferClicked(t: TaskReview) {
    let prev = PREVIOUS_URL;
    if (t.taskTypeCode === 'VT_0') {
      swal.fire({
        title: 'Perdavimas negalimas, pasirinkite tipą.',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Atšaukti',
        confirmButtonText: 'Keisti tipą',
        // confirmButtonClass: 'btn-primary',
        customClass: {
          confirmButton: 'btn-primary'
        },
        // buttonsStyling: false
      })
        .then((r) => {
          if (r.value) {
            this.updateTaskTypeClicked(t);
          }
        });
    } else {
      this._openDialog(TransferFormComponent, t, { autoFocus: false }).afterClosed().pipe(filter((s) => s))
        .subscribe((r) => this.router.navigate(prev ? [PREVIOUS_URL] : ['taskboard', REQUESTS, FOR_ME]));
    }
  }

  protected updateStateClicked(task: TaskReview, tree: any) {
    this._openDialog(UpdateRequestStateComponent, { data: { ...task, childsTree: tree }, hasChildren: task.isTree }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: EntityUpdated<TaskStateChanged>) => {
        if (res.success) {
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
            this.triggerUpdateChildTree++;
            this.detectChanges();
          }, (err) => {
            subs.unsubscribe();
          });
        }
      });
  }

  protected updatePriorityClicked(task: TaskReview) {
    this._openDialog(UpdateTaskPriorityComponent, task, { autoFocus: false }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: EntityUpdated<TaskPriorityChanged>) => {
        if (res.success) {
          this.task.taskPriorityChanged(res.entity);
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
            this.detectChanges();
          }, (err) => {
            subs.unsubscribe();
          });
          swal.fire({ title: "Prioritetas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else {
          swal.fire({ title: "Prioriteto pakeisti nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
        }
      });
  }

  protected updateProductClicked(task: TaskReview) {
    this._openDialog(UpdateRequestProductComponent, task, { autoFocus: false }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: EntityUpdated<TaskProductChanged>) => {
        if (res) {
          swal.fire({ title: "Produktas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        }
        // console.log('getTaskDetails a1');
        const subs = this._updateTask(task).subscribe((refreshedTask) => {
          subs.unsubscribe();
        }, (err) => {
          subs.unsubscribe();
        });
      });
  }

  protected updateProjectStageClicked(task: TaskReview) {
    this._openDialog(UpdateProjectStageComponent, task, { autoFocus: false }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: any) => {
        if (res) {
          swal.fire({ title: "Etapas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        }
        // console.log('getTaskDetails a2');
        const subs = this._updateTask(task).subscribe((refreshedTask) => {
          subs.unsubscribe();
        }, (err) => {
          subs.unsubscribe();
        });
      });
  }

  protected updateProjectClicked(task: TaskReview) {
    this.dialog.open(ProjectEditModalComponent, { panelClass: 'edit-project-modal', data: { task: task, isNew: false }, maxHeight: '90vh', minWidth: '700px' }).afterClosed().pipe(filter((s) => s))
      .subscribe((res: any) => {
        if (res) {
          swal.fire({ title: "Projektas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        }
        // console.log('getTaskDetails a3');
        const subs = this._updateTask(task).subscribe((refreshedTask) => {
          subs.unsubscribe();
        }, (err) => {
          subs.unsubscribe();
        });
      });
  }

  protected updateDeadlineClicked(task: TaskReview) {
    this._openDialog(UpdateTaskDeadlineComponent, { task, componentType: 'deadLine' }).afterClosed()
      .subscribe((res: any) => {
        if (res && res.success === true) {
          // console.log('getTaskDetails a4');
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
          }, (err) => {
            subs.unsubscribe();
          });
          swal.fire({ title: "Terminas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else if (res && res.success === false) {
          swal.fire({
            title: "Termino pakeisti nepavyko", text: res.errorMessage, timer: 7000, showConfirmButton: false, icon: 'error'
          });
        }
      });
  }

  protected updateDateStartClicked(task: TaskReview) {
    this._openDialog(UpdateTaskDeadlineComponent, { task, componentType: 'dateStart' }).afterClosed()
      .subscribe((res: any) => {
        if (res && res.success === true) {
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
          }, (err) => {
            subs.unsubscribe();
          });
          swal.fire({ title: "Vykdymo data pakeista sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else if (res && res.success === false) {
          swal.fire({
            title: "Vykdymo datos pakeisti nepavyko", text: res.errorMessage, timer: 7000, showConfirmButton: false, icon: 'error'
          });
        }
      });
  }

  protected updatePartnerClick(task: TaskReview) {
    this._openDialog(UpdateTaskPartnerComponent, task).afterClosed().pipe(filter((s) => s))
      .subscribe((res: any) => {
        if (res.success) {
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
          }, (err) => {
            subs.unsubscribe();
          });
          // this.detectChanges();
          swal.fire({ title: "Partneris pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else {
          swal.fire({ title: "Partneris pakeisti nepavyko", timer: 2000, showConfirmButton: false, icon: 'error' });
        }
      });
  }

  protected updateClientNeedsClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, { ...task, componentType: 'clientNeeds' }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Klientų poreikis pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateClientTypeClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, { ...task, componentType: 'clientType' }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Kliento tipas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateSourceClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, { ...task, componentType: 'source' }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Šaltinis pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateSaleTypeClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, { ...task, componentType: 'saleType' }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Pardavimo tipas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateSaleValueClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, { ...task, componentType: 'saleValue' }, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Pardavimo vertė pakeista sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateDelegateClicked(task: TaskReview) {
    this._openDialog(UpdateDelegateModalComponent, task, { autoFocus: false }).afterClosed()
      .subscribe((res: any) => {
        if (res && res.success === true) {
          // console.log('getTaskDetails a5');
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
          }, (err) => {
            subs.unsubscribe();
          });
          swal.fire({ title: "Atstovas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else if (res && res.success === false) {
          swal.fire({
            title: "Atstovo pakeisti nepavyko", text: res.errorMessage, timer: 7000, showConfirmButton: false, icon: 'error'
          });
        }
      });
  }

  protected updateServiceClicked(task: TaskReview) {
    this._openDialog(UpdateTaskServiceComponent, task, { autoFocus: false }).afterClosed()
      .subscribe((res: any) => {
        if (res && res.success === true) {
          // console.log('getTaskDetails a6');
          const subs = this._updateTask(task).subscribe((refreshedTask) => {
            subs.unsubscribe();
          }, (err) => {
            subs.unsubscribe();
          });
          swal.fire({ title: "Paslauga pakeista sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        } else if (res && res.success === false) {
          swal.fire({
            title: "Paslaugos pakeisti nepavyko", text: res.errorMessage, timer: 7000, showConfirmButton: false, icon: 'error'
          });
        }
      });
  }

  protected updateTaskTypeClicked(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(UpdateRequestTypeComponent, task, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Veiklos tipas pakeistas sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
        if (res === 'VT_3') {
          this.updateSalesFields(task);
        }
      }
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected updateSalesFields(task: TaskReview) {
    // autofocus false to fix bug with preset value and suggestions dropdown
    this._openDialog(SalesFieldsModalComponent, task, { autoFocus: false }).afterClosed().subscribe(res => {
      if (res) {
        swal.fire({ title: "Pardavimo laukai pakeisti sėkmingai", timer: 2000, showConfirmButton: false, icon: 'success' });
      }
      // console.log('getTaskDetails a7');
      const subs = this._updateTask(task).subscribe((refreshedTask) => {
        subs.unsubscribe();
      }, (err) => {
        subs.unsubscribe();
      });
    });
  }

  protected jobClicked(job: any, task: TaskReview) {
    if ((task.isEditable || task.stateId === TASK_STATE_COMPLETED) && job.isEditable) {
      // if (job.executorId == this.authService.user.personId) {
      this.sidebarService.openJobForm(JobFormModel.editJob(this.taskboardDataService, job));
      // } else {
      //   this.sidebarService.openJobForm(JobFormModel.reviewJobNotOwned(this.taskboardDataService, job));
      // }
    } else {
      this.sidebarService.openJobForm(JobFormModel.reviewJob(this.taskboardDataService, job));
    }
  }

  protected addCommentJobClicked(comment, t: TaskReview) {
    setTimeout(_ => {
      if (t.isEditable) {
        this.sidebarService.openJobForm(JobFormModel.newCommentJob(this.taskboardDataService, comment, t));
      }
    });
  }

  protected addJobWithCommentClicked(comment: CommentTask, t: TaskReview) {
    setTimeout(_ => {
      if (t.isEditable) {
        this.sidebarService.openJobForm(JobFormModel.newJobWithComment(this.taskboardDataService, t, comment));
      }
    });
  }

  protected refreshTask() {
    this._refreshTask(this.task.id);
  }

  protected addJobClicked(t: TaskReview) {
    setTimeout(_ => {
      this.sidebarService.openJobForm(JobFormModel.newTaskJob(this.taskboardDataService, t));
    });
  }

  protected initiateProcess(t: TaskReview) {
    const modalRef = this.dialog.open(ProjectEditModalComponent, { panelClass: 'edit-project-modal', data: { task: t, isNew: true }, maxHeight: '90vh', minWidth: '700px' });
    this._subscriptions.push(modalRef.afterClosed().subscribe((data) => {
      if (data) {
        // this._subscriptions.push(this._updateTask(t).subscribe());
        swal.fire({
          title: 'Projektas sukurtas sėkmingai',
          allowOutsideClick: false,
          timer: 1500,
          icon: 'success',
          showConfirmButton: false
        });
        this.router.navigate(['/projects', t.isRequest ? REQUESTS : TASKS, REVIEW, t.id]);
      }
    }, (err) => {
      swal.fire({
        title: 'Projekto sukurti nepavyko',
        allowOutsideClick: false,
        timer: 1500,
        icon: 'error'
      });
    }));
  }

  protected addToProject(t: TaskReview) {
    const modalRef = this.dialog.open(SelectProjectModalComponent, { panelClass: 'add-to-project-modal', data: t, maxHeight: '90vh', minWidth: '1000px' });
    this._subscriptions.push(modalRef.afterClosed().subscribe((data) => {
      if (data) {
        // console.log('getTaskDetails a8');
        this._subscriptions.push(this._updateTask(t).subscribe());
      }
    }));
  }

  //Event handlers
  protected _personChanged(e, t: TaskReview, personsSubj) {
    const after = e.after;
    this.tasksBackend.changeTaskPerson(
      t.id,
      {
        personsTypeId: after.typeId,
        personsId: parseInt(after.id.toString()),
        // personsSubj: t.isRequest ? 'atstovas' : 'darbuotojas'
        personsSubj: t.isRequest ? (personsSubj ? personsSubj : 'atstovas') : 'darbuotojas'
      }).subscribe((r) => {
        this.personsChanged.next(true);
      },
        (err) => {
          swal.fire({
            title: 'Pakeisti darbuotojo statusą nepavyko',
            allowOutsideClick: false,
            timer: 2500,
            icon: 'error'
          });
        });
  }

  protected _addChildTask(t: TaskReview) {
    this.taskboardDataService.parentTask = t;
    this.router.navigate(['taskboard/t/n']);
    setTimeout(_ => this.taskboardDataService.parentTask = undefined);
  }

  protected _continueProcess(t: TaskReview) {
    this.taskboardDataService.parentTask = t;
    this.router.navigate(['taskboard/r/n']);
    setTimeout(_ => this.taskboardDataService.parentTask = undefined);
  }

  protected _goTo(id) {
    if (id) {
      this.router.navigate(['../', id], { relativeTo: this.route });
    }
  }

  _taskChildSelected(task: any) {
    if (!task) {
      return;
    }
    const what = task.isRequest == '1' ? REQUESTS : TASKS;
    this.router.navigate(['taskboard', what, REVIEW, task.id]);
  }

  nearestActionClicked(task: TaskReview, text?: string) {

    this._subscriptions.push(this._openDialog(NearestActionFormComponent, NearestActionFormComponent.formInput(task, text)).afterClosed().pipe(filter((s) => s))
      .subscribe((res: EntityCreated<NearestAction>) => {
        // console.log('getTaskDetails a9');
        this._updateTask(task).subscribe();
      }));
  }

  //TODO: distribute
  distributeTaskClicked(task: TaskReview) {
    this._subscriptions.push(this._openDialog(DistributeTaskComponent, task)
      .afterClosed().pipe(
        filter((s) => s)
        , switchMap((s) => s)
      )
      .subscribe(res => {
        swal.fire({
          title: "Užklausa paskirstyta",
          timer: 2000,
          showConfirmButton: false,
          icon: 'success'
        })
      }));
  }

  private _updateNearestActions(task: TaskReview) { //not used method
    return this.tasksBackend.getNearestActionsHistory(task.id).pipe(filter(notNull), tap((a) => this._actionsHistory.next(a)));
  }

  showNearestActions(task: TaskReview) {
    const modalRef = this.dialog.open(ActionModalComponent, { panelClass: 'custom-modal' });
    modalRef.componentInstance._task = task;
    modalRef.componentInstance._taskId = task.id;
    this._subscriptions.push(modalRef.beforeClosed().subscribe((data) => {
      // console.log('getTaskDetails a10');
      this._subscriptions.push(this._updateTask(task).subscribe());
    }));
  }

  private _setupStateListeners() {
    this._subscriptions.push(this.state.subscribe(JOB_UPDATED, (job: JobModel) => {
      this.sidebarService.close();
      if (job) {
        this.task.jobUpdated(job);
        if (this.jobsList) {
          this.jobsList.recalc();
        }
      }
    }));
    this._subscriptions.push(this.state.subscribe(JOB_ENTERED, (jobEntered: JobEntered) => {
      if (jobEntered.commented) {
        this.task.taskCommented(jobEntered.commented);
      }
      this.task.jobEntered(jobEntered.job);
      this.chg.markForCheck();
      setTimeout(() => {
        this.detectChanges();
      })
    }));

  }

  private _setupPersonsLoading() {
    this._subscriptions.push(this._loadedTaskObs().subscribe((t) => {
      if (t.isRequest) {
        this.getPersons = () => { return this.tasksBackend.getRequestPersonsByTask(t.id) };
      } else {
        this.getPersons = () => { return this.tasksBackend.getTaskPersonsByTask(t.id) };
      }
    }));
  }

  _timerClicked() {
    if (!this._taskTimer) {
      this._subscriptions.push(this._openDialog(TimerDialogComponent, {}, { minWidth: 600 }).afterClosed().subscribe(res => {
        if (!res || res == CANCELLED) {
          return;
        }
        this.timerService.createTimer(this.task.id, res);
      }));
    } else {
      this.timerService.toggleTimer(this._taskTimer.id);
    }
    setTimeout(_ => {
      try {
        this.chg.detectChanges()
      } catch (err) { }
    });
  }

  protected jobsTabOpened() {
    setTimeout((_) => { this.jobsList && this.jobsList.recalc() }, 0);
  }

  protected commented(taskCommented: TaskCommented) {
    this.task.taskCommented(taskCommented);
    this.detectChanges();
  }

  //Helpers
  protected _openDialog(cmp, data, opts = DEFAULT_MODAL_OPTS): MatDialogRef<any> {
    return this.dialog.open(cmp, { ...opts, data: data });
  }

  protected _refreshData(refresh: (r: TaskReview) => TaskReview) {
    this._taskObs().subscribe((r) => {
      this.task = refresh(r);
      this.detectChanges();
    });
  }

  protected _taskObs(): Observable<TaskReview> {
    return this._loadedTaskObs().pipe(take(1));
  }

  protected _loadedTaskObs(): Observable<TaskReview> {
    return this._data.pipe(filter((d) => d.data != null), map((d) => d.data));
  }

  protected detectChanges() {
    try {
      this.chg.detectChanges();
      this.commentsComponent.chg.detectChanges();
    } catch (e) {
      console.error(e, 'TaskReviewBase: tried to detect changes on destroyed view.');
    }
  }

  _openChildTasks() {
    const tabset: HTMLElement = this.tabSet.nativeElement;
    window.scroll(0, tabset.getBoundingClientRect().top);
    // this.childTasksTabActive = true;
    this.currentTabId = 'tab03';
  }

  protected detectChangesDpaList() {
    try {
      this.chg.detectChanges();
      this.dpaListComponent.chg.detectChanges();
    } catch (e) {
      console.error(e, 'TaskReviewBase: tried to detect changes on destroyed view.');
    }
  }

  projectDpaTabOpened(task) {
    this.projectDpaList = [];
    this._subscriptions.push(this.tasksBackend.getProjectDpa(task.id).subscribe((resp) => {
      this.projectDpaList = resp;
      this.detectChangesDpaList();
      setTimeout(() => {
        this.dpaListComponent.refreshTable();
      }, 0);
    }));
  }
}

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { LIST } from "../../../../services/lists/lists.data.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TasksBackend } from "../../../../backend/tasks.backend";
import { Subscription } from "rxjs";
import swal from 'sweetalert2';
import { SuggestionsComponent } from '../../../../components/suggestions/suggestions.component';

@Component({
  selector: 'app-update-request-type',
  templateUrl: './update-request-type.component.html',
  styleUrls: ['./update-request-type.component.scss']
})
export class UpdateRequestTypeComponent implements OnInit, OnDestroy {
  protected _updateTypeForm: UntypedFormGroup;
  protected _taskTypes = LIST.TASK_TYPES_FILTER;
  protected _clientNeeds = LIST.SALES_CLIENTS_NEEDS_LIST;
  protected _clientTypes = LIST.MARKETING_CLIENT_TYPES_LIST;
  protected _saleTypes = LIST.SALES_TYPES_LIST;
  protected _sources = LIST.MARKETING_SOURCES_LIST;
  protected _initialTypeSelector;
  protected _updating = false;
  protected _updated = false;
  private _subscription: Subscription;

  componentType: 'requestType' | 'clientNeeds' | 'clientType' | 'saleType' | 'saleValue' | 'source' = 'requestType';

  _isInserted: any;

  taskTypesList: any;
  _taskTypeSelected: any;
  _subscriptions: Subscription[] = [];

  @ViewChild('taskTypesList') set content(taskTypesList: SuggestionsComponent) {
    this.taskTypesList = taskTypesList;
    if (taskTypesList) {
      this._subscriptions.push(taskTypesList.suggestionObjSelected.subscribe((s) => {
        this._taskTypeSelected = s;
      }))
    }
  }

  constructor(private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) protected _data,
    private _tasksBackend: TasksBackend,
    private _dialogRef: MatDialogRef<UpdateRequestTypeComponent>) {
    _data.componentType ? this.componentType = _data.componentType : null;
  }

  ngOnInit() {
    this._buildForm();
  }

  cancel() {
    this._dialogRef.close();
  }

  protected _submit(fg: UntypedFormGroup) {
    if (!fg.valid) {
      return;
    }
    this._updating = true;
    let propName;
    if (this.componentType === 'requestType') propName = 'typeId';
    if (this.componentType === 'clientNeeds') propName = 'klNeed';
    if (this.componentType === 'clientType') propName = 'klType';
    if (this.componentType === 'saleType') propName = 'saleType';
    if (this.componentType === 'saleValue') propName = 'pCost';
    if (this.componentType === 'source') propName = 'sourceId';
    const updateArr = [];
    const updateObj: any = {};
    if (fg.value.text) updateObj.text = fg.value.text;
    updateObj[propName] = fg.value.typeId;
    updateArr.push(updateObj);
    this._subscription = this._tasksBackend.updateTaskType(this._data.id, updateArr)
      .subscribe(res => {
        this._updating = false;
        if (res) {
          this._updated = true;
        }
        if (this.componentType === 'requestType' && this._taskTypeSelected.code === 'VT_3') {
          this._dialogRef.close('VT_3');
        } else {
          this._dialogRef.close(true);
        }
      }, (err) => {
        this._updating = false;
        swal.fire({
          title: 'Įvyko klaida',
          timer: 2000,
          showConfirmButton: false,
          icon: 'error'
        });
      });
  }

  private _buildForm() {
    let initValue;
    if (this.componentType === 'requestType') initValue = this._data.taskTypeId;
    if (this.componentType === 'clientNeeds') initValue = this._data.clientNeedId;
    if (this.componentType === 'clientType') initValue = this._data.clientTypeId;
    if (this.componentType === 'saleType') initValue = this._data.saleTypeId;
    if (this.componentType === 'saleValue') initValue = this._data.saleValue;
    if (this.componentType === 'source') initValue = this._data.sourceId;
    this._updateTypeForm = this._formBuilder.group({
      typeId: [initValue],
      text: []
    });
  }

  ngOnDestroy(): void {
    if (this._subscription && !this._subscription.closed) {
      this._subscription.unsubscribe();
    }
  }
}
